import React, { useState } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export const useAlert = () => {

    const classes = useStyles();

    const [showing, setShowing] = useState(false);
    const [message, setMessage] = useState();
    const [severity, setSeverity] = useState();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowing(false);
    };

    return [
        showing ? (
            <div className={classes.root}>
                <Snackbar open={showing}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={severity}>
                        {message}
                    </Alert>
                </Snackbar>
            </div>
        ) : null,
        (message, severity) => {
            setMessage(message);
            setSeverity(severity);
            setShowing(true);
        } //Show alert
    ];
}