import React, { useState } from "react";
import Spinner from "../images/spinner_arrow.svg";

export const usePageLoader = () => {
    const [loading, setLoading] = useState(false);

    return [
        loading ? (
            <div className="fp-container">
                <img src={Spinner} className="fp-loader" alt="Loading" />
            </div>
        ) : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hider loader
    ];
}