import { Grid } from "@material-ui/core";
import React from "react";
import { Redirect } from "react-router-dom";
import { Copyright } from "../components/Copyright";
import { Register } from "../components/Register";
import * as AuthenticationUtil from "../util/AuthenticationUtil";

export const RegisterPage = () => {

    if (AuthenticationUtil.isAuthenticated()) {
        return <Redirect to="/questionnaire" />;
    }

    return (
        <div className="loginPage">
            <Register />
            <Copyright />
        </div>
    );
}