import '../App.css';
import { Box, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from "react-router-dom";
import * as AuthenticationUtil from "../util/AuthenticationUtil";
import { Questionnaire } from '../components/Questionnaire';
import { usePageLoader } from "../util/usePageLoader";
import { useAlert } from "../util/useAlert";

import { Copyright } from "../components/Copyright";
import * as Endpoints from "../util/Endpoints";
import * as ApiUtil from "../util/ApiUtil";

import { Logout } from "../components/Logout";

export const NextQuestionnairePage = (props) => {

    const [response, setResponse] = useState({});

    const [loader, showLoader, hideLoader] = usePageLoader();
    const [alert, showAlert] = useAlert();

    useEffect(
        () => {

            const getActiveGroupQuestionnaire = async () => {
                try {
                    showLoader();
                    const responseData = await ApiUtil.callBackend(Endpoints.activeGroupQuestionnaire2, "GET");
                    console.log(responseData);

                    setResponse(responseData);
                    localStorage.setItem("current_group", "");

                    if (!responseData.status) {
                        showAlert(responseData.message, "error");
                    }
                } catch (error) {
                    console.log(error);
                    showAlert(error.message, "error");
                } finally {
                    hideLoader();
                }
            }
            getActiveGroupQuestionnaire();

        }, []);


    if (!AuthenticationUtil.isAuthenticated()) {
        return <Redirect to="/login" />;
    }

    if (response.data && response.data.groupQuestionnaires.length === 1) {
        const groupQuestionnaire = response.data.groupQuestionnaires[0];
        const questionRouter = `question/unanswered_by_questionnaire/${groupQuestionnaire.questionnaire.id}/${groupQuestionnaire.id}`;
        return <Redirect to={questionRouter} />;
    }

    return (
        <div className="center-text">
            <Box m={1}>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Logout />
                    </Grid>
                </Grid>
            </Box>
            <Grid container justify="center">
                <Grid item>
                    <h2>Active Questionnaire</h2>
                </Grid>
            </Grid>

            <Grid container spacing={2} justify="center">
                {response.status && response.data.groupQuestionnaires.length > 0 ? response.data.groupQuestionnaires.map(questionnaire =>
                    <Questionnaire key={questionnaire.id} data={questionnaire} />
                ) : <h3>No questionnaire found.</h3>}
            </Grid>
            <Copyright />
            {loader}
            {alert}
        </div>
    );
}