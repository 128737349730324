const baseUrl = "https://stmri-backend.ki.se/api/";
//const baseUrl = "http://13.51.36.60:8080/api/";
//const baseUrl = "http://localhost:8080/api/";

const login = baseUrl + "auth";

const activeGroupQuestionnaire = baseUrl + "group_questionnaire/active";
const activeGroupQuestionnaire1 = baseUrl + "group_questionnaire/active/NeedToAskFirst";
const activeGroupQuestionnaire2 = baseUrl + "group_questionnaire/active/Cancer Screening Group";
const unansweredQuestionByQuestionnaire = baseUrl + "question/unanswered_by_questionnaire";
const saveAnswer = baseUrl + "answer";
const finishAnswer = baseUrl + "group_questionnaire_answer_finished";
const registerUser = baseUrl + "public/sign_up/";
const consentText = baseUrl + "public/get_consent";
const welcomeThankYouText = baseUrl + "public/welcome_thank_you";
const registrationUrl = baseUrl + "public/registration_url";
const registration = baseUrl + "public/registration";

export {
    baseUrl,
    login,
    activeGroupQuestionnaire,
    activeGroupQuestionnaire1,
    activeGroupQuestionnaire2,
    unansweredQuestionByQuestionnaire,
    saveAnswer,
    finishAnswer,
    registerUser,
    consentText,
    welcomeThankYouText,
    registrationUrl,
    registration
}
