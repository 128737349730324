import '../App.css';
import { Box, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Question } from '../components/Question';
import * as AuthenticationUtil from "../util/AuthenticationUtil";

import { Paper, Button } from '@material-ui/core';

import { Copyright } from '../components/Copyright';

import * as Endpoints from "../util/Endpoints";
import * as ApiUtil from "../util/ApiUtil";
import { usePageLoader } from '../util/usePageLoader';
import { useAlert } from '../util/useAlert';

import { Logout } from '../components/Logout';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

export const QuestionPage = (props) => {

    const { questionnaireId } = useParams();
    const { groupQuestionnaireId } = useParams();

    const apiQuestion = `${Endpoints.unansweredQuestionByQuestionnaire}?questionnaireId=${questionnaireId}&groupQuestionnaireId=${groupQuestionnaireId}`;

    const [answers, setAnswers] = useState([]);

    const [response, setResponse] = useState({});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentQuestionAnswer, setCurrentQuestionAnswer] = useState({});

    const [proceedToNextQuestionnaire, setProceedToNextQuestionnaire] = useState(false);
    const [doneAnswering, setDoneAnswering] = useState(false);

    const [thankYouText, setThankYouText] = useState();

    const [loader, showLoader, hideLoader] = usePageLoader();
    const [alert, showAlert] = useAlert();

    const [open, setOpen] = useState(false);
    const [openThankYou, setOpenThankYou] = useState(false);

    const saveAnswer = (answerData) => {
        console.log(answers);
        console.log(answerData);
        updateAnswerState(answerData);
    }

    const updateAnswerState = (newAnswerData) => {
        let shouldAppend = false;
        let answersBackup = answers;
        for (let i = 0; i < answersBackup.length; i++) {
            if (answersBackup[i].questionId === newAnswerData.questionId) {
                if (newAnswerData.answer) {
                    answersBackup[i].answer = newAnswerData.answer;
                } else {
                    answersBackup[i].answerOptions = newAnswerData.answerOptions;
                }
                shouldAppend = true;
                break;
            }
        }
        if (!shouldAppend) {
            answersBackup.push(newAnswerData);
        }
        setAnswers(answersBackup);
        updateCurrentQuestionAnswer(currentQuestionIndex);
        console.log(currentQuestionAnswer);
    }

    const updateCurrentQuestionAnswer = (index) => {
        let currentAnswerUpdated = false;
        let answersBackup = answers;
        for (let i = 0; i < answersBackup.length; i++) {
            if (answersBackup[i].questionId === response.data.list[index].id) {
                setCurrentQuestionAnswer(answersBackup[i]);
                currentAnswerUpdated = true;
                break;
            }
        }
        if (!currentAnswerUpdated) {
            setCurrentQuestionAnswer({});
        }
    }

    useEffect(() => {
        getQuestion();
    }, []);

    if (proceedToNextQuestionnaire && localStorage.getItem("current_group") === "NeedToAskFirst") {
        return <Redirect to="/next_questionnaire" />
    }

    if (doneAnswering) {
        return <Redirect to="/questionnaire" />
    }

    const getQuestion = async () => {

        try {
            showLoader();
            const responseData = await ApiUtil.callBackend(apiQuestion, "GET");
            console.log(responseData);

            setResponse(responseData);

            if (!responseData.status) {
                showAlert(responseData.message, "error");
            }
        } catch (error) {
            console.log(error);
            showAlert(error.message, "error");
        } finally {
            hideLoader();
        }
    }

    if (!AuthenticationUtil.isAuthenticated()) {
        return <Redirect to="/login" />;
    }

    const handleNavigatePrevious = () => {
        if (currentQuestionIndex - 1 < 0) {
            showAlert("Inga fler frågor", "error");
        } else {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            updateCurrentQuestionAnswer(currentQuestionIndex - 1);
        }
    }

    const handleNavigateNext = async (e) => {
        if (currentQuestionIndex + 1 < response.data.list.length) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            updateCurrentQuestionAnswer(currentQuestionIndex + 1);
        } else if (answers.length > 0) {
            setOpen(true);
        } else {
            showAlert("Vänligen svara på frågorna", "error");
        }
    }

    const submitAnswer = async () => {
        //Save answer
        try {
            showLoader();
            let answerRequestBody = {
                "groupQuestionnaireId": groupQuestionnaireId,
                "answers": answers
            }
            let finishAnswerRequestBody = {
                "groupQuestionnaireId": groupQuestionnaireId
            }
            console.log(answerRequestBody);
            const responseData = await ApiUtil.callBackend(Endpoints.saveAnswer, "POST", answerRequestBody);
            const finishSurvey = await ApiUtil.callBackend(Endpoints.finishAnswer, "POST", finishAnswerRequestBody);
            console.log(responseData);

            if (responseData.status && finishSurvey.status) {
                setProceedToNextQuestionnaire(false);
                await eligible();
                if (!proceedToNextQuestionnaire) {
                    //setDoneAnswering(true);
                    if (localStorage.getItem("current_group") === "NeedToAskFirst") {
                        setThankYouText('Tack för att du svarade på enkäten! Du kommer tyvärr inte kunna att delta i studien eftersom du svarade "Ja" på minst en av de föregående frågorna.');
                        setOpenThankYou(true);
                    } else {
                        await getThankYouText();
                    }
                }
                //getQuestion();
            } else {
                showAlert(responseData.message, "error");
            }
        } catch (error) {
            console.log(error);
            showAlert(error.message, "error");
        } finally {
            hideLoader();
            setOpen(false);
        }
    }

    const getThankYouText = async () => {

        try {
            showLoader();
            const responseData = await ApiUtil.callBackend(Endpoints.welcomeThankYouText, "GET");
            console.log(responseData);

            setResponse(responseData);

            if (!responseData.status) {
                showAlert(responseData.message, "error");
            }
            setThankYouText(responseData.data.welcome.thankYouTextSwedish);
            setOpenThankYou(true);

        } catch (error) {
            console.log(error);
            showAlert(error.message, "error");
        } finally {
            hideLoader();
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleCloseThankYou = () => {
        setOpenThankYou(false);
        setDoneAnswering(true);
    };

    const eligible = async () => {
        let eligibleForFurtherQuestions = true;
        answers.forEach(answer => {
            response.data.list.forEach(question => {
                if (answer.questionId === question.id
                    && answer.answerOptions
                    && answer.answerOptions[0].questionOptionId) {
                    question.questionOptions.forEach(questionOption => {
                        if (parseInt(answer.answerOptions[0].questionOptionId) === questionOption.id) {
                            let title = questionOption.title.toUpperCase();
                            if (title === "JA") {
                                eligibleForFurtherQuestions = false;
                            }
                        }
                    });
                }
            });
        });
        if (eligibleForFurtherQuestions) {
            setProceedToNextQuestionnaire(true);
        }
    };

    return (
        <>
            <Box m={1}>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Logout />
                    </Grid>
                </Grid>


                <Grid container justify="center" align="center">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <h2>ScreenTrust MRI</h2>
                    </Grid>

                    <Grid item xs={12} sm={11} md={10} lg={9}>

                        <Paper>
                            <Box p={1}>
                                {response.status && response.data.list && response.data.list.length > 0 ?
                                    (<>
                                        <Grid container spacing={1} align="left">
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                {(<Question
                                                    question={response.data.list[currentQuestionIndex]}
                                                    saveAnswer={saveAnswer}
                                                    defaultAnswer={currentQuestionAnswer}
                                                />)}
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                                {currentQuestionIndex !== 0 ?
                                                    <Button color="primary" onClick={handleNavigatePrevious}>Föregående</Button> : ""}
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                                <Button color="primary" onClick={handleNavigateNext}>
                                                    {response.data.list.length - 1 !== currentQuestionIndex ? "Nästa" : "Slutförande"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <h3>{currentQuestionIndex + 1} / {response.data.list.length}</h3>
                                            </Grid>
                                            </Grid>

                                    </>) : <h3>Ingen mera frågor.</h3>}
                            </Box>
                        </Paper>

                    </Grid>
                </Grid>
                <Grid container justify="center">
                    <Grid item>
                        <Copyright />
                    </Grid>
                </Grid>
            </Box>

            {loader}
            {alert}


            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Bekräftelse"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Är du säker på att du vill skicka in dina svar?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">Nej</Button>
                    <Button onClick={submitAnswer} color="primary" autoFocus>JA</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openThankYou}
                onClose={handleCloseThankYou}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Tack"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {thankYouText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseThankYou} color="primary">Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}