import { Grid, Box, Typography } from "@material-ui/core";
import { Button, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Copyright } from "../components/Copyright";
import * as AuthenticationUtil from "../util/AuthenticationUtil";
import * as Endpoints from "../util/Endpoints";
import * as ApiUtil from "../util/ApiUtil";

import { useAlert } from "../util/useAlert";
import { usePageLoader } from "../util/usePageLoader";
import logo from "../images/logo-sakra-brost.png";

export const ConsentPage = () => {

    const history = useHistory();

    const [consentText, setConsentText] = useState("");

    const [alert, showAlert] = useAlert();
    const [loader, showLoader, hideLoader] = usePageLoader();

    const [accepted, setAccepted] = useState();

    useEffect(() => {
        const getConsentText = async () => {
            try {
                showLoader();
                const responseData = await ApiUtil.callBackend(Endpoints.consentText, "GET");
                console.log(responseData);

                if (!responseData.status) {
                    showAlert(responseData.message, "error");
                }
                setConsentText(responseData.data.consent);
            } catch (error) {
                console.log(error);
                showAlert(error.message, "error");
            } finally {
                hideLoader();
            }
        }
        getConsentText();
    }, []);

    if (AuthenticationUtil.isAuthenticated()) {
        return <Redirect to="/questionnaire" />;
    }

    const handleClickDecline = () => {
        showAlert("Du måste acceptera för att fortsätta.", "error");
    };

    if (accepted) {
        history.push("/login");
    }

    const handleClickAccept = () => {
        setAccepted(true);
    };

    return (
        <div>
            <Box m={2}>
                <Grid container justify="center" spacing={1} align="center">
                    <Grid item>
                        <img src={logo} height={90} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Paper elevation={3}>
                            <Box p={2}>
                                <Typography variant="body1">
                                    <div dangerouslySetInnerHTML={{__html: consentText}} />
                                    </Typography>
                            </Box>
                        </Paper>
                    </Grid>


                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Button
                            color="primary"
                            onClick={handleClickAccept}
                        >
                            Jag Samtycker
                        </Button>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Button
                            color="secondary"
                            onClick={handleClickDecline}
                        >
                            Jag Samtycker Inte
                        </Button>
                    </Grid>

                </Grid>
            </Box>
            <Copyright />
            {loader}
            {alert}
        </div>
    );
}