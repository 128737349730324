import * as Endpoints from "./Endpoints";

const callBackend = async (endpoint, method, requestBody = {}) => {
    //show loader
    let headers = {
        'Content-Type': 'application/json',
        'language': 'SWEDISH'
    };
    if (localStorage.getItem("token")) {
        headers.Authorization = localStorage.getItem("token");
    }

    let options = {
        method: method,
        headers: headers
    };
    if (method !== "GET") {
        options.body = JSON.stringify(requestBody);
    }

    return await fetch(endpoint, options)
        .then(function (response) {
            return response.json();
        });
};

const uploadFile = async (endpoint, requestBody = {}, method) => {
    //show loader

    let headers = {};
    if (localStorage.getItem("token")) {
        headers.Authorization = localStorage.getItem("token");
    }

    let options = {
        method: method || "POST",
        headers: headers,
        body: requestBody
    };

    return await fetch(endpoint, options)
        .then(function (response) {
            return response.json();
        }).catch((error) => {
            console.log('Error: ', error);
            //alert message
        }).finally(function () {
            //hide loader
        });
};

const downloadFile = async (endpoint) => {
    //show loader

    let headers = {};
    if (localStorage.getItem("token")) {
        headers.Authorization = localStorage.getItem("token");
        headers.timeZoneOffsetInMinute = new Date().getTimezoneOffset()
    }

    let options = {
        method: "GET",
        headers: headers
    };

    fetch(endpoint, options).then(function (response) {
        return response.json();
    }).then(function (jsonData) {
        window.open(
            Endpoints.baseUrl + jsonData.data.fileUrl,
            '_blank'
        );
    }).catch((error) => {
        console.log('Error: ', error);
        //alert message
    }).finally(function () {
        //hide loader
    });
};

export {
    callBackend,
    uploadFile,
    downloadFile
}