import React, { useState, useEffect } from 'react';
import { TextField, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

export const Question = ({ question, saveAnswer, defaultAnswer }) => {

    const [updatedAnswer, setUpdatedAnswer] = useState();

    useEffect(() => {
        console.log("default Answer: " + JSON.stringify(defaultAnswer));
        if (defaultAnswer && defaultAnswer.answer) {
            setUpdatedAnswer(defaultAnswer);
        } else if (defaultAnswer && defaultAnswer.answerOptions) {
            setUpdatedAnswer({ "answer": defaultAnswer.answerOptions[0].questionOptionId });
        } else {
            setUpdatedAnswer("");
        }
    }, [question]);

    const handleOnChangeInput = (e) => {
        setUpdatedAnswer({
            "answer": e.target.value
        });
        saveAnswer({
            "answer": e.target.value,
            "questionId": question.id
        });
    }

    const handleOnChangeRadio = (e) => {
        setUpdatedAnswer({
            "answer": e.target.value
        })
        saveAnswer({
            "questionId": question.id,
            "answerOptions": [
                { "questionOptionId": e.target.value }
            ]
        })
    }

    return (
        <>
            <h2>{question.title}</h2>
            <h3>{question.body}</h3>
            {question.questionType.title === "DATA_INPUT" ? (
                <TextField
                    required
                    id="outlined-error-helper-text"
                    label="Svar"
                    name="answer"
                    value={updatedAnswer && updatedAnswer.answer ? updatedAnswer.answer : ""}
                    onChange={e => handleOnChangeInput(e)}
                />
            ) : ""}

            {question.questionType.title === "SELECT_ONE" ? (
                <RadioGroup
                    aria-label="answer"
                    name="answer"
                    value={updatedAnswer && updatedAnswer.answer ? updatedAnswer.answer : ""}
                    onChange={e => handleOnChangeRadio(e)}>
                    {question.questionOptions.map(questionOption =>
                        <FormControlLabel key={questionOption.id} value={questionOption.id.toString()} control={<Radio />} label={questionOption.title} />
                    )}
                </RadioGroup>
            ) : ""}
        </>
    );
}