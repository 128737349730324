const isAuthenticated = () => {
    let accessToken = localStorage.getItem("token");
    let date = localStorage.getItem("token_date");
    let expireIn = localStorage.getItem("expire_in");//Minutes
    if (accessToken && date && expireIn) {
        let currentMillis = (new Date()).getTime();
        let expireMillis = new Date(date).getTime() + (expireIn * 60 * 1000);
        if (currentMillis > expireMillis) {
            localStorage.clear();
            return false;
        }
        return true;
    }
    return false;
};

export { isAuthenticated };