import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#01a3e0"
        },
        secondary: {
            main: "#d87fa0"
        }
        /* text: {
            primary: "#01a3e0"
        } */
    },
    typography: {
        htmlFontSize: 11
    },
    shape: {
        borderRadius: 30
    },
    overrides: {
        MuiFilledInput: {
            root: {
                backgroundColor: "green"
            }
        },
        MuiInputLabel: {
            root: {
                //backgroundColor: "yellow"
            }
        },
        MuiTextField: {
            root: {}
        },
        MuiButton: {
            root: {
                textTransform: "none",
                padding: "10px",
            }
        }
    },
    props: {
        MuiButton: {
            disableRipple: true,
            variant: "contained",
            color: "primary",
            fullWidth: true
        },
        MuiTextField: {
            variant: "outlined",
            fullWidth: true,
            margin: "normal",
            InputLabelProps: {
                //shrink: true
            }
        },
        MuiPaper: {
            elevation: 12
        },
        MuiCard: {
            elevation: 12
        }
    }
});